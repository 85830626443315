
.nav-pills {
	border-bottom: 1px solid $border-color;
	padding-bottom: 1rem;
	.nav-link {
		border: 1px solid $border-color;
		padding: .5rem 1.75rem;
		@media (max-width: 767px) {
			padding: .5rem .5rem;
		}
	}
	&.nav-pills-custom {
		border-bottom: 0;
		.nav-item {
			margin-top: .875rem;
			margin-right: 0.187rem;
			.nav-link {
				border-radius: 0;
				padding: .22rem .4rem;
				background: $nav-pills-custom-bg;
				color: #b1b0b0;
				font-size: 0.75rem;
				&.active {
					background: #e6e6e6;
				}
			}
		}
	}
}