
////////// COLOR SYSTEM //////////

$blue:              #5E50F9;
$indigo:            #6610f2;
$purple:            #6a008a;
$pink:              #E91E63;
$red:               #f96868;
$orange:            #f2a654;
$yellow:            #f6e84e;
$green:             #46c35f;
$teal:              #58d8a3;
$cyan:              #57c7d4;
$black:             #000;
$white:             #ffffff;
$white-smoke:       #f2f7f8;
$violet:            #41478a;
$darkslategray :    #2e383e;
$dodger-blue :      #3498db;


$colors: (
  blue:             $blue,
  indigo:           $indigo,
  purple:           $purple,
  pink:             $pink,
  red:              $red,
  orange:           $orange,
  yellow:           $yellow,
  green:            $green,
  teal:             $teal,
  cyan:             $cyan,
  white:            $white,
  gray:             #434a54,
  gray-light:       #aab2bd,
  gray-lighter:     #e8eff4,
  gray-lightest:    #e6e9ed,
  gray-dark:        #0f1531,
  black:            #000000
);


$theme-colors: (
  primary:         #6640b2,
  secondary:       #a3a4a5,
  success:         #00d082,
  info:            #1cbccd,
  warning:         #ffbf36,
  danger:          #f83e37,
  light:           #f8f9fa,
  dark:            #282f3a
);

$theme-gradient-colors: (
  primary:         linear-gradient(230deg, #759bff, #843cf6),
  secondary:       linear-gradient(to right, #e7ebf0, #868e96),
  success:         linear-gradient(45deg, #7bffce, #30c93e),
  info:            linear-gradient(to bottom, #0e4cfd, #6a8eff),
  warning:         linear-gradient(135deg, #ffc480, #ff763b),
  danger:          linear-gradient(316deg, #fc5286, #fbaaa2),
  light:           linear-gradient(to right, #cfd9df 0%, #e2ebf0 100%),
  dark:            linear-gradient(to right, #7d7979 0%, #000000 100%)
);

////////// COLOR SYSTEM //////////

////////// COLOR VARIABLES //////////
$content-bg: #f6f7fb;
$border-color: #f3f3f3;

////////// COLOR VARIABLES //////////



////////// SOCIAL COLORS //////////

$social-colors: (
  twitter: #2caae1,
  facebook: #3b579d,
  google: #dc4a38,
  linkedin: #0177b5,
  pinterest: #cc2127,
  youtube: #e52d27,
  github: #333333,
  behance: #1769ff,
  dribbble: #ea4c89,
  reddit: #ff4500
);

////////// SOCIAL COLORS //////////

////////// FONTS//

$type1:     'Source Sans Pro', sans-serif;

$default-font-size: .875rem; // 14px as base font size

$text-muted: #b1b0b0;
$body-color: #001737;

////////// FONT VARIABLES //////////

///////// FOOTER ////////
$footer-height: 75px;
$footer-bg: $content-bg;
$footer-color: color(dark);
///////// FOOTER ////////

///////// BUTTONS ////////

$button-fixed-width:        150px;
$btn-padding-y:       .875rem;
$btn-padding-x:       1.5rem;
$btn-line-height:     1;

$btn-padding-y-xs:    .5rem;
$btn-padding-x-xs:    .75rem;

$btn-padding-y-sm:    .50rem;
$btn-padding-x-sm:    .81rem;

$btn-padding-y-lg:    1rem;
$btn-padding-x-lg:    3rem;

$btn-font-size:       .875rem;
$btn-font-size-xs:    .625rem;
$btn-font-size-sm:    .875rem;
$btn-font-size-lg:    .875rem;

$btn-border-radius:         .1875rem;
$btn-border-radius-xs:      .1875rem;
$btn-border-radius-sm:      .1875rem;
$btn-border-radius-lg:      .1875rem;

///////// BUTTONS ////////


/////////  TABS VARIABLES //////////
$nav-tabs-border-color:             #ebedf2;
$nav-tabs-link-bg:                  #f6f8fa;
$nav-tabs-link-color:               #000000;
$nav-pills-custom-bg:               $white;

/////////  FORMS /////////

$input-bg:                color(white);
$input-border-radius:     2px;
$input-placeholder-color: #c9c8c8;
$input-font-size: .875rem;

$input-padding-y:       .875rem;
$input-padding-x:       1.375rem;
$input-line-height:     1;

$input-padding-y-xs:    .5rem;
$input-padding-x-xs:    .75rem;

$input-padding-y-sm:    .50rem;
$input-padding-x-sm:    .81rem;

$input-padding-y-lg:    .94rem;
$input-padding-x-lg:    1.94rem;

$input-height:          2.875rem;
$input-height-sm:       2.575rem;
$input-height-lg:       3.175rem;

///////// FORMS /////////

////////  DROPDOWNS ///////
$dropdown-color: $body-color;
$dropdown-border-color: $border-color;
$dropdown-divider-bg:   $border-color;
$dropdown-link-color:   $body-color;
$dropdown-header-color: $body-color;
$dropdown-link-hover-bg: #eaeaf1;
////////  DROPDOWNS ///////

//////// TABLES ////////
$table-color: $body-color;
$table-accent-bg: $content-bg;
$table-hover-bg:  #eaeaf1;
$table-cell-padding: 1.25rem .9375rem;
$table-border-color: $border-color;

$table-inverse-bg: #2a2b32;
$table-inverse-color: color(white);

//////// TABLES ////////



////////// MEASUREMENT AND PROPERTY VARIABLES //////////

$border-property: 1px solid $border-color;
$card-spacing-y: 1.875rem;
$card-padding-y: 1.4rem;
$card-padding-x: 1.875rem;
$card-border-radius: 0;
$card-border-color: #d8e0ec;
$card-box-shadow: none;
$card-title-color: #001737;
$card-description-color: #76838f;
$grid-gutter-width: 30px;
$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;
////////// OTHER VARIABLES //////////

////////// BREAD CRUMBS VARIABLES //////////

////////// SIDEBAR ////////
$sidebar-width-lg: 240px;
$sidebar-width-mini: 185px;
$sidebar-width-icon: 70px;


$sidebar-bg: #27293d;
$sidebar-menu-color: #959cb6;
$sidebar-menu-active-bg: $content-bg;
$sidebar-menu-active-color: #dfe4fa;
$sidebar-menu-hover-bg: #3a3f51;
$sidebar-menu-hover-color: #d0cfcf;
$sidebar-submenu-color: $sidebar-menu-color;
$sidebar-submenu-hover-bg: initial;
$sidebar-submenu-hover-color: #bebdc2;
$sidebar-submenu-active-color: #dfe4fa;
$sidebar-menu-icon-color: $sidebar-menu-color;
$sidebar-menu-arrow-color: $sidebar-menu-color;

$sidebar-dark-bg: #223e9c;
$sidebar-dark-menu-color: $white;
$sidebar-dark-menu-active-bg: #183188;
$sidebar-dark-menu-active-color: $white;
$sidebar-dark-menu-hover-bg: $sidebar-dark-menu-active-bg;
$sidebar-dark-menu-hover-color: $white;
$sidebar-dark-submenu-color: $sidebar-dark-menu-color;
$sidebar-dark-submenu-hover-bg: initial;
$sidebar-dark-submenu-hover-color: #93a8ff;
$sidebar-dark-submenu-active-color: $sidebar-dark-submenu-hover-color;
$sidebar-dark-menu-icon-color: $sidebar-dark-menu-color;
$sidebar-dark-menu-arrow-color: $white;

$sidebar-menu-font-size: .937rem;
$sidebar-menu-padding: 0.76rem 2.125rem;

$sidebar-submenu-padding: .25rem 0 .875rem 2.5rem;
$sidebar-submenu-font-size: .937rem;
$sidebar-submenu-item-padding: .75rem 1rem;

$sidebar-icon-font-size:  1.125rem;
$sidebar-arrow-font-size: .625rem;

$sidebar-profile-bg: transparent;
$sidebar-profile-padding: 0 1.625rem 2.25rem 1.188rem;

$sidebar-mini-menu-padding: .8125rem 1rem .8125rem 1rem;

$sidebar-icon-only-menu-padding: .5rem 1.625rem .5rem 1.188rem;
$sidebar-icon-only-submenu-padding: 0 0 0 1.5rem;

$icon-only-collapse-width: 190px;
$icon-only-menu-bg: $sidebar-bg;

///////// SIDEBAR ////////

$icon-only-collapse-width: 190px;
$icon-only-menu-bg-light: #ffffff;
$icon-only-menu-bg-dark: $sidebar-dark-bg;


///////// NAVBAR ////////

$navbar-default-bg: theme-color(primary);
$navbar-full-height: 262px;
$navbar-height: 65px;
$navbar-menu-color: $white;
$navbar-font-size: 1rem;
$navbar-icon-font-size: .9375rem;

///////// NAVBAR ////////

