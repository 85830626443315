/* Layouts */

// Sidebar Icon Only
.sidebar-icon-only {
  @media (min-width: 992px) {
    .page-body-wrapper {
      width: calc(100% - #{$sidebar-width-icon});
    }
    .navbar-mini {
      &.fixed-top {
        left: $sidebar-width-icon;
      }
    }
    .sidebar {
      width: $sidebar-width-icon;
      > .nav {
        margin-top: 1.8rem;
      }
      .nav {
        overflow: visible;
        .sidebar-category {
          margin: 1.2rem 2rem 0.4rem 2rem;
          p {
            display: none;
          }
          span {
            width: 5px;
            height: 5px;
            display: block;
            background: $white;
            border-radius: 100%;
          }
        }
        .nav-item {
          position: relative;
          .nav-link {
            display: block;
            padding-left: .5rem;
            padding-right: .5rem;            
            text-align: center;
            position: static;
            .menu-title,
            .badge,.menu-sub-title {
              display: none;
            }
            .menu-title {
              @include border-radius(0 5px 5px 0px);
            }
            i {
              &.menu-icon {
                margin-right: 0;
                margin-left: 0;
                margin-bottom: 0;
              }
              &.menu-arrow {
                display: none;
              }
            }
            &[aria-expanded] {
              .menu-title {
                @include border-radius(0 5px 0 0px);
              }
            }
          }
          .collapse {
            display: none;
          }
          &.hover-open {
            .nav-link {
              .menu-title {
                @include display-flex;
                @include align-items(center);
                background: $icon-only-menu-bg-dark;
                padding: 0.5rem 1.4rem;
                left: $sidebar-width-icon;
                position: absolute;
                text-align: left;
                top: 0;
                bottom: 0;
                width: $icon-only-collapse-width;
                z-index: 1;
                line-height: 1.8;
                box-shadow: 4px 0px 7px 0px rgba(182, 185, 189, 0.25);
                &:after {
                  display: none;
                }
              }
              &:hover {
                .menu-title {
                  background: $icon-only-menu-bg-dark;
                }
              }
            }
            .collapse,
            .collapsing {
              display: block;
              padding: .5rem 0;
              background: $icon-only-menu-bg-dark;
              @include border-radius(0 0 5px 0);
              position: absolute;
              left: $sidebar-width-icon;
              width: $icon-only-collapse-width;
              box-shadow: 4px 4px 7px 0px rgba(182, 185, 189, 0.25);
            }
          }
        }
        &.sub-menu {
          padding: $sidebar-icon-only-submenu-padding;
          .nav-item {
            .nav-link {
              text-align: left;
              padding-left: 20px;
            }
          }
        }
      }
    }

  }
}
