/* Pws-tabs */

.pws_tabs_container {
	ul.pws_tabs_controll {
		li {
      &:first-child {
        a {
          @include border-radius(5px 0px 0px 0px);
        }
      }
      &:last-child {
        a {
          border-right: 1px solid theme-color(primary);
          @include border-radius(0px 5px 0px 0px);
        }
      }
			a {
				border: 1px solid theme-color(primary);
				border-bottom: none;
        border-right: none;
				background: #fff;
				color: theme-color(primary);
				padding: 0.6em 1.3em;
        margin-right: 0;

				&:hover {
					background: #fff;
					color: theme-color(primary);
				}

				&.pws_tab_active {
					background: theme-color(primary);
					color: #fff;
				}
			}
		}
	}
  // Styles for vertical Tab
  &.pws_tabs_vertical {
    .pws_tabs_controll {
      border-right: none;
			border-bottom:none;
      li {
        &:first-child {
          a {
            @include border-radius(5px 0px 0px 0px);
          }
        }
        &:last-child {
          a {
            border-bottom: 1px solid theme-color(primary);
            @include border-radius(0px 0px 0px 5px);
            border-right: 0px;
          }
        }
        a {
          @include border-radius(0px);
          border: 1px solid theme-color(primary);
          border-right: none;
          border-bottom: none;
          margin-bottom: 0;
        }
      }
    }
  }
  // Styles for vertical Tab
}
